<template>
  <div>
    <NewsletterPopupClient></NewsletterPopupClient>
    <div class="slides top-cover-slide" v-if="coverSlider.length">
      <ClientOnly fallback-tag="span">
        <swiper :rewind="true" :watchSlidesProgress="true" :spaceBetween="0" :slidesPerView="1" :navigation="true"
          :pagination="true" :grabCursor="true" :centeredSlides="false" :history="{
            key: 'slide-cover-main-top',
          }" :autoplay="{
            delay: 3000,
            disableOnInteraction: false,
          }" :modules="modules" class="mySwiper swiper">

          <swiper-slide v-for="slide in coverSlider" :key="slide.id" class="swiper-slide">
            <NuxtLinkLocale v-if="slide.url" :to="slide.url" :key="slide.url" aria-label="Image-Link">
              <!-- <NuxtImg preload class="cover-slider-img" :src="imgUrl(slide.image.url, 900, 50)" :modifiers="{}" /> -->
              <img class="cover-slider-img " :src="imgUrl(slide.image.url, 900)" alt="Image" id="slide-img" />
            </NuxtLinkLocale>
            <!-- <div v-else>{{ slide }}</div> -->
          </swiper-slide>
        </swiper>
        <template #fallback>
          <div class="border homeShadeWrap "></div>
        </template>
       
      </ClientOnly>
    </div>
  
    <div class="container">
      <h2 class="text-center mid-text">{{ $t('this_weeks_highlights') }}</h2>
    </div>


    <ClientOnly fallbackTag="span">
      <div class="slides" v-if="sliderImages.length">
        <swiper :rewind="true" :watchSlidesProgress="true" :spaceBetween="0" :slidesPerView="slidesPerView"
          :navigation="true" :pagination="true" :grabCursor="true" :centeredSlides="false" :history="{
            key: 'cover-slide-bottom',
          }" :autoplay="{
            delay: 4000,
            disableOnInteraction: false,
          }" :modules="modules" class="mySwiper swiper">
          <swiper-slide v-for="slide in sliderImages" :key="slide.id + '_sws'" class="swiper-slide">
            <template v-if="slide?.url">
              <NuxtLinkLocale :to="slide.url" :key="slide.id + '_nl'" aria-label="Image-Link">
                <NuxtImg format="webp" preload :key="slide.id + '_img'" class="cover-slider-bottom-img"
                  :src="imgUrl(slide.image.url, 350)" :modifiers="{}" alt="silder-Images" id="slide-img" />
                <!-- <img :key="slide.id + '_img'" class="cover-slider-bottom-img" :src="imgUrl(slide.image.url, 350)"
                  alt="Image" id="slide-img" /> -->
              </NuxtLinkLocale>
            </template>
          </swiper-slide>
        </swiper>
        <!-- <promo-products></promo-products> -->

      </div>
      <template #fallback>
        <div class="shadeWrap  placeholder bg-light loading for_slider_multi mb-5">
            <div class="itemshed pl-1"></div>
            <div class="itemshed pl-1"></div>
            <div class="itemshed"></div>
        </div>
      </template>

    </ClientOnly>

    <WelcomeProductsList></WelcomeProductsList>
    <!-- fallback start -->
    <div class="welcome-list-placeholder mt-14 " v-if="!productExist">
      <div class="container">
        <div class="row ">
          <div class="mediaQuery">
            <div class="colmns" v-for="i in 5" :key="i" :class="{ 'lastDiv': i == 5,'fourthDiv': i == 4,'thirdDiv': i == 3 }">
              <div  class="customCls  product-box shadeLoad loading" >
            <div class="product-search-img"></div>
            <div class="contentLoad ">
              <h6 class="discInfo "></h6>
              <div class="discInfo "></div>
              <div class="discInfo" v-if="isAuthenticated"></div>
            </div>
          </div>
            </div>
          </div>
          <!-- .Second Line .. -->
           <div class="mt-10 secondLine"> 
                <div class="mediaQuery ">
            <div class="colmns" v-for="i in 5" :key="i" :class="{ 'lastDiv': i == 5,'fourthDiv': i == 4,'thirdDiv': i == 3 }">
              <div  class="customCls  product-box shadeLoad loading" >
            <div class="product-search-img"></div>
            <div class="contentLoad ">
              <h6 class="discInfo "></h6>
              <div class="discInfo "></div>
              <div class="discInfo" v-if="isAuthenticated"></div>
            </div>
          </div>
            </div>
          </div>
        </div>
    
        </div>
      </div>
      <!-- fallback end -->
    </div>
  </div>
</template>

<script setup>

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, History, Autoplay } from "swiper/modules";
import { useCmsStore } from "~/store/cms/cmsStore";
const productStore = useProductStore();
import WelcomeProductsList from "~/components/WelcomeProductsList.vue";
import { useProductStore } from "~/store/ProductStore";
import NewsletterPopupClient from "~/components/widgets/newsletter-popup.client.vue";
import { useUserStore } from "~/store/UserStore";
const userStore = useUserStore();
const isAuthenticated = computed(() => userStore.isAuthenticated);
const products = computed(() => productStore.welcomProducts);
const productExist = computed(() => !!Object.keys(products.value).length);
const modules = [Navigation, Pagination, Autoplay];
const cmsStore = useCmsStore();
const sliderImages = computed(() => cmsStore.sliderImages);
const coverSlider = computed(() => cmsStore.coverSlider);
const slidesPerView = ref(3);
// const imgUrl = (url, w, q = 80) => cmsStore.getImageUrl(`/cdn-cgi/image/width=${w},quality=${q},format=webp,fit=pad,background=%23FFFFFF${url}`);
const imgUrl = (url, w, q = 100) => cmsStore.getImageUrl(`/cdn-cgi/image/width=${w},quality=${q},fit=pad,background=%23FFFFFF${url}`);

</script>

<style scoped>
#slide-img {
  width: -webkit-fill-available;
}

.slides {
  padding-bottom: 10px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: block;
  margin: 0 2px;
  /*justify-content: center;
  align-items: center;*/
}

.swiper {
  width: 100%;
  height: fit-content;
  box-sizing: content-box;
}

.category-text {
  margin: 0 0 0 0 !important;
}

.slider-placeholder {
  max-height: 530px;
  width: 100%;
}

.slide {
  /* flex: 1; */
  /* Makes each item take up equal space */
  min-height: 530px;
  /* Sets a minimum height */
  background-color: #a3a3a3
    /* Optional: for visibility */
}

.homeShadeWrap{
  background-color: #dad7d7 ;
  height: 1230px;
}

.shadeWrap{
  display: flex; 
  gap: 3px; 
  height: 810px;
}
 .itemshed {
  flex: 1;
  background-color: #a3a3a3 
}

.customCls {
  min-height: 380px;
}

.mediaQuery {
  display: grid;
  grid-gap: 0; /* Default gap */
}

.colmns {
  padding: 10px;
  text-align: center;
}


@media (max-width: 480px) {
  .homeShadeWrap{
  height: 110px;
}
  .shadeWrap{
  height: 95px;
}
  .mediaQuery {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }

  .thirdDiv, .fourthDiv, .lastDiv, .secondLine { display: none;}


}

@media (min-width: 481px) and (max-width: 575.98px) {
  .homeShadeWrap{
  height: 150px;
}
  .shadeWrap{
  height: 130px;
}
}
@media (min-width: 576px) and (max-width: 680px) {
  .homeShadeWrap{
  height: 190px;
}
  .shadeWrap{
  height: 160px;
}
}
@media (min-width: 681px) and (max-width: 767.99px) {
  .homeShadeWrap{
  height: 270px;
}
  .shadeWrap{
  height: 180px;
}
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .homeShadeWrap{
  height: 270px;
}
  .shadeWrap{
  height: 230px;
}
}

@media (min-width: 992px) and (max-width: 1199.99px) {
  .homeShadeWrap{
  height: 370px;
}
  .shadeWrap{
  height: 280px;
}
}
@media (min-width: 1200px) and (max-width: 1600px) {
  .homeShadeWrap{
  height: 480px;
}
.shadeWrap{
  height: 370px;
}
}
@media (min-width: 1601px) and (max-width: 1920px) {
  .homeShadeWrap{
  height: 530px;
}
.shadeWrap{
  height: 445px;
}
}

@media (min-width: 1921px) and (max-width: 2000px) {
  .homeShadeWrap{
  height: 620px;
}
.shadeWrap{
  height: 460px;
}
}
@media (min-width: 2001px) and (max-width: 2500.99px) {
  .homeShadeWrap{
  height: 870px;
}
.shadeWrap{
  height: 580px;
}
}


@media (min-width: 481px) and (max-width: 992px) {
  .mediaQuery {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 12px; 
  }
  .lastDiv, .secondLine {display: none;}
}

@media (min-width: 993px) {
  .mediaQuery {
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 18px; 
  }
}
</style>
